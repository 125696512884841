import shouldForwardProp from "@styled-system/should-forward-prop";
import styled from "styled-components";
import {
    background,
    border,
    color,
    compose,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    size as sz,
    space,
    system,
    typography,
} from "styled-system";
import { colors } from "../../constants/colors";

export const composeSx =
    (...styles) =>
    ({ sx, ...rest }) =>
        compose(...styles)({ ...rest, ...sx });

const gapProps = system({
    gap: {
        property: "gap",
        scale: "space",
    },
    rowGap: {
        property: "row-gap",
        scale: "space",
    },
    columnGap: {
        property: "column-gap",
        scale: "space",
    },
});
const otherProps = system({
    cursor: {
        property: "cursor",
    },
});
export const composeAll = composeSx(
    position,
    flexbox,
    grid,
    gapProps,
    layout,
    space,
    background,
    color,
    border,
    shadow,
    typography,
    otherProps,
);

export const Box = styled.div.withConfig({ shouldForwardProp })`
    ${composeAll}
`;

export const Flex = styled.div.withConfig({ shouldForwardProp })`
    display: flex;
    ${composeAll}
`;

export const Grid = styled.div.withConfig({ shouldForwardProp })`
    display: grid;
    ${composeAll}
`;

export const Text = styled.p.withConfig({ shouldForwardProp })`
    margin: 0;
    ${composeSx(position, layout, space, color, typography)}
`;

export const Image = styled.img.withConfig({ shouldForwardProp })`
    width: 100%;
    height: auto;
    ${composeSx(space, position, sz)}
    ${({ w, h, maxWidth, maxHeight, minHeight, minWidth }) =>
        compose(layout)({ width: w, height: h, maxWidth, maxHeight, minHeight, minWidth })}
`;

export const sizes = {
    xs: {
        fontSize: "xs",
        height: "44px",
        px: "10px",
        borderRadius: "5px",
    },
    sm: {
        fontSize: "sm",
        height: "50px",
        px: "12px",
        borderRadius: "6px",
    },
    md: {
        fontSize: "md",
        height: "56px",
        px: "14px",
        borderRadius: "7px",
    },
    lg: {
        fontSize: "lg",
        height: "64px",
        px: "16px",
        borderRadius: "8px",
    },
    xl: {
        fontSize: "2xl",
        height: "72px",
        px: "18px",
        borderRadius: "8px",
    },
    rlg: {
        fontSize: ["md", "md", "lg"],
        height: ["56px", "56px", "64px"],
        px: ["14px", "16px"],
        borderRadius: ["7px", "7px", "8px"],
    },
    rxl: {
        fontSize: ["md", "lg", "2xl"],
        height: ["56px", "64px", "72px"],
        px: ["14px", "16px", "18px"],
        borderRadius: ["7px", "8px"],
    },
};

const defaultButtonStyles = {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: colors.primary,
    color: "#fff",
    fontWeight: "600",
    border: "1px solid transparent",
    cursor: "pointer",
};
const buttonFn = ({ size, ...rest }) =>
    composeAll({
        ...defaultButtonStyles,
        ...(sizes[size] || sizes.md),
        ...rest,
    });
export const Button = styled.button.withConfig({ shouldForwardProp })`
    ${buttonFn}
    :focus {
        opacity: 0.9;
    }
    :disabled {
        cursor: not-allowed;
    }
`;
export const ButtonLink = styled.a.withConfig({ shouldForwardProp })`
    ${buttonFn}
    :focus {
        opacity: 0.9;
    }
`;
const defaultButtonOutlineStyles = {
    ...defaultButtonStyles,
    backgroundColor: "transparent",
};
const buttonOutlineFn = ({ size, color, ...rest }) =>
    composeAll({
        ...defaultButtonOutlineStyles,
        ...{
            borderColor: color || colors.primary,
            bg: "transparent",
            color: color || colors.primary,
        },
        ...(sizes[size] || sizes.md),
        ...rest,
    });
export const ButtonOutline = styled.button.withConfig({ shouldForwardProp })`
    ${buttonOutlineFn}
    :focus {
        opacity: 0.9;
    }
    :disabled {
        cursor: not-allowed;
    }
`;
export const ButtonOutlineLink = styled.a.withConfig({ shouldForwardProp })`
    ${buttonOutlineFn}
    :focus {
        opacity: 0.9;
    }
`;

export const BorderBox = styled.div`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
`;
